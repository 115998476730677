<template>
  <div class="container">
    <pcPublicHeader />
    <div class="public-content">
      <div class="page-title-item">个人中心</div>
      <div class="user-container">
        <pcUserHeader pageKey="order" />
        <div class="status-nav-item">
          <div class="item" :class="status === 0 ? 'active' : ''" @click="status = 0">全部</div>
          <div class="item" :class="status === 2 ? 'active' : ''" @click="status = 2">处理中</div>
          <div class="item" :class="status === 4 ? 'active' : ''" @click="status = 4">已完成</div>
          <div class="item" :class="status === 5 ? 'active' : ''" @click="status = 5">退单中</div>
          <div class="item" :class="status === 7 ? 'active' : ''" @click="status = 7">已退款</div>
        </div>
        <div class="search-container">
          <div class="search-item">
            <div class="long-input-item">
              <div class="input"><input v-model="goodsName" type="text" placeholder="输入商品标题进行搜索" /></div>
              <div class="btn" @click="handleSearch">订单搜索</div>
            </div>
            <div class="more-btn" :class="showMore ? 'active' : ''" @click="showMore = !showMore">更多筛选条件 <img src="../../assets/images/arrow-down-icon.png" /></div>
            <div class="flex-1"></div>
          </div>
          <div v-if="showMore" class="search-item">
            <div class="input-item">
              <div class="name">时间：</div>
              <div class="input">
                <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%;"></el-date-picker>
              </div>
            </div>
            <div class="input-item">
              <div class="name">下单参数：</div>
              <div class="input">
                <el-input v-model="buyParams" type="text" placeholder="请输入" />
              </div>
            </div>
            <div class="input-item">
              <div class="name">订单编号：</div>
              <div class="input">
                <el-input v-model="orderSN" type="text" placeholder="请输入" />
              </div>
            </div>
            <div class="btn-item">
              <div class="btn blue" @click="handleSearch">查询</div>
              <div class="btn" @click="handleResetOrderList">重置</div>
            </div>
          </div>
        </div>
        <div class="order-container">
          <div class="order-header-item">
            <div class="item row-1">订单状态</div>
            <div class="item row-2">订单号</div>
            <div class="item row-3">单价</div>
            <div class="item row-4">数量</div>
            <div class="item row-5">实付款</div>
            <div class="item row-6">状态</div>
            <div class="item row-7">操作</div>
          </div>
          <div v-if="orderList.length == 0 && finish && !loading" class="empty-item">
            <div class="img img-cover">
              <img src="../../assets/pc-images/empty-img.png" />
            </div>
          </div>
          <div v-else class="order-list">
            <div class="order-item" v-for="(item, index) in orderList" :key="index">
              <div class="order-head">
                <div class="time">{{ $TOOL.dateFormat(item.CreateAt * 1000, 'yyyy-MM-dd hh:mm') }}</div>
                <div class="orderno">订单号：{{ item.OrderSN }} <span @click="handleCopy(item.OrderSN)">复制</span></div>
              </div>
              <div class="order-content">
                <div class="item img-item">
                  <div class="img img-cover">
                    <img v-if="item.Img" :src="item.Img" />
                    <img v-else src="../../assets/images/no-image-icon.png" />
                  </div>
                </div>
                <div class="item goods-item">
                  <div class="name">{{ item.GoodsName }}</div>
                  <div v-for="(params, paramsIndex) in item.BuyParams" :key="paramsIndex" class="params">
                    <div class="val">{{ params.value }}</div>
                    <div class="copy" @click="handleCopy(params.value)">复制</div>
                  </div>
                </div>
                <div class="item price">¥ {{ Math.round(item.SellingPrice * 100000) / 100000 }}</div>
                <div class="item count">{{ item.BuyNumber }}</div>
                <div class="item money">¥ {{ Math.round(item.Amount * 100000) / 100000 }}</div>
                <div class="item detail">
                  <div class="value">下单：{{ item.BuyNumber }}</div>
                  <div class="value">执行：{{ item.CurrentNum - item.StartNum }}</div>
                  <div class="value">初始：<span>{{ item.StartNum }}</span></div>
                  <div class="value">当前：<span>{{ item.CurrentNum }}</span></div>
                </div>
                <div class="item status">
                  <span :class="'status-' + item.OrderStatus">{{ orderStatusObj[item.OrderStatus] }}</span>
                </div>
                <div class="item operate">
                  <div class="btn blue" @click="handleShowOrderDetail(item.Id)">订单详情</div>
                  <router-link :to="{ path: '/goodsDetail', query: {id: item.GoodsId} }" class="btn a">再次下单</router-link>
                  <div class="btn" v-if="item.CanTui.includes(`${item.OrderStatus}`) && item.OrderStatus != 4 && item.OrderStatus != 5 && item.OrderStatus != 6 && item.OrderStatus != 7" @click="handleAppRefund(item)">申请退款</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="total > pageCount" class="pagination-item">
            <el-pagination background layout="prev, pager, next" :page-size="pageCount" :total="total" @current-change="handleCurrentPageChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <pc-order-detail v-if="dialog.detail" ref="detailDialog" @success="handleSuccess" @closed="dialog.detail=false"></pc-order-detail>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'
import pcUserHeader from '@/components/pcUserHeader'
import pcOrderDetail from '@/components/pcOrderDetail'

export default {
  name: "Order",
  components: {
    pcPublicHeader,
    pcUserHeader,
    pcOrderDetail
  },
  data() {
    return {
      dialog: {
        detail: false
      },
      showMore: false,
      orderList: [],
      pageCount: 10,
      page: 1,
      total: 0,
      finish: false,
      loading: false,
      status: 0,
      orderSN: '',
      buyParams: '',
      goodsName: '',
      dateRange: [],
      orderParams: {},
      orderStatusObj: { '-1': '待付款', '1': '处理中', '2': '处理中', '3': '处理中', '4': '已完成', '5': '退单中', '6': '已退单', '7': '已退款', '8': '处理中' }
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    status() {
      this.handleResetOrderList()
    },
  },
  mounted() {
    this.getOrderList()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    handleSearch() {
      const { orderSN, buyParams, dateRange, goodsName } = this
      const params = {}
      if (orderSN) {
        params.OrderSN = orderSN
      }
      if (goodsName) {
        params.GoodsName = goodsName
      }
      if (buyParams) {
        params.BuyParams = buyParams
      }
      if (dateRange && dateRange.length > 0) {
        var startTime = dateRange[0]
        var endTime = dateRange[1]
        params.StartTime = this.$TOOL.dateFormat(startTime,'yyyy-MM-dd')
        params.EndTime = this.$TOOL.dateFormat(endTime,'yyyy-MM-dd')
      }
      this.orderParams = params
      this.page = 1
      this.total = 0
      this.orderList = []
      this.finish = false
      this.loading = false
      this.getOrderList()
    },
    handleResetOrderList() {
      this.goodsName = ''
      this.orderSN = ''
      this.buyParams = ''
      this.dateRange = []
      this.orderParams = {}
      this.page = 1
      this.total = 0
      this.finish = false
      this.loading = false
      this.orderList = []
      this.getOrderList()
    },
    handleCurrentPageChange(val) {
      this.page = val
      this.getOrderList()
    },
    async getOrderList() {
      const { pageCount, page, orderParams, status } = this
      const params = {
        PageCount: pageCount, 
        Page: page,
        ...orderParams
      }
      if (status) {
        params.Status = status
      }
      var res = await this.$API.orderList.post(params)
      const data = []
      for (let index = 0; index < res.info.length; index++) {
        const item = res.info[index]
        data.push({
          ...item,
          CanTui: item.CanTui.split(','),
          BuyParams: JSON.parse(item.BuyParams)
        })
      }
      this.total = res.count
      this.orderList = [
        ...data
      ]
      this.finish = data.length < pageCount
      this.loading = false
    },
    async handleAppRefund(item) {
      this.$dialog.confirm({
        type: 'info',
        className: 'pc-dialog',
        title: '申请退款',
        message: '确定要申请退款吗？',
        cancelButtonText: '取消',
        confirmButtonText: '确认',
      }).then(async () => {
      var res = await this.$API.appRefund.post({
          Id: item.Id
        })
        if (res.error == 0) {
          this.$toast.success(res.info)
          this.getOrderList()
        } else {
          this.$dialog.alert({
            type: 'danger',
            className: 'pc-dialog',
            title: '提示',
            message: res.info,
            confirmButtonText: '确定',
          })
        }
      }).catch(() => {
      })
    },
    handleSuccess() {
      this.getOrderList()
    },
    handleShowOrderDetail(id) {
      this.dialog.detail = true
      this.$nextTick(() => {
        this.$refs.detailDialog.open(id)
      })
    }
  }
};
</script>

<style lang="less" scoped>
.page-title-item {
  padding: 30px 0 24px;
  line-height: 46px;
  color: #3d3d42;
  font-size: 32px;
  font-weight: bold;
}
.user-container {
  background-color: #fafbff;
  border-radius: 8px;
  overflow: hidden;
}
.status-nav-item {
  width: 100%;
  height: 64px;
  border-radius: 8px 8px 0 0;
  background-color: #fafbff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .item {
    cursor: pointer;
    width: 124px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;
    margin-right: 30px;
    font-size: 16px;
    color: #3d3d42;
    &.active,
    &:hover {
      font-weight: bold;
      color: #6a5ffe;
      background-color: #fff;
      box-shadow: 4px -4px 12px 0 rgba(0, 0, 0, .06);
    }
  }
}
.search-container {
  padding: 40px 30px 10px 30px;
  background-color: #fff;
  .search-item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .long-input-item {
      width: 400px;
      height: 32px;
      border: 1px solid #ccc;
      border-radius: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .input {
        flex: 1;
        padding: 0 10px;
      }
      .btn {
        cursor: pointer;
        text-align: center;
        line-height: 32px;
        width: 80px;
        background-color: #e9edf5;
        border-left: 1px solid #ccc;
        font-size: 12px;
        font-weight: bold;
        color: #3d3d42;
      }
    }
    .more-btn {
      cursor: pointer;
      margin-left: 16px;
      font-size: 12px;
      font-weight: bold;
      color: #3d3d42;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        img {
          transform: rotate(180deg);
        }
      }
      img {
        margin-left: 4px;
        width: 8px;
        height: 8px;
        transition: all .5s;
      }
    }
    .input-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      .name {
        color: #0b212c;
      }
      .input {
        width: 240px;
        height: 36px;
      }
    }
    .btn-item {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .btn {
        cursor: pointer;
        width: 78px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: 1px solid #3d3d42;
        border-radius: 6px;
        color: #3d3d42;
        margin-left: 10px;
        &.blue {
          border-width: 0;
          background-color: #6a5ffe;
          color: #fff;
        }
      }
    }
  }
}
.order-container {
  font-size: 14px;
  padding: 0 30px;
  background-color: #fff;
  .order-header-item {
    height: 48px;
    border: 1px solid #bfbfbf;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    margin-bottom: 62px;
    .item {
      color: #333;
      &.row-1 {
        padding-left: 50px;
      }
      &.row-2 {
        padding-left: 65px;
      }
      &.row-3 {
        padding-left: 300px;
      }
      &.row-4 {
        padding-left: 85px;
      }
      &.row-5 {
        padding-left: 85px;
      }
      &.row-6 {
        padding-left: 161px;
      }
      &.row-7 {
        padding-left: 326px;
      }
    }
  }
  .order-list {
    overflow: hidden;
    .order-item {
      border: 1px solid #eff4ff;
      margin-bottom: 20px;
      .order-head {
        height: 48px;
        padding: 0 20px;
        background-color: #eff4ff;
        display: flex;
        align-items: center;
        color: #333;
        .time {
          font-weight: bold;
        }
        .orderno {
          margin-left: 30px;
          span {
            cursor: pointer;
            margin-right: 6px;
            padding: 0 6px;
            line-height: 22px;
            border-radius: 4px;
            background-color: rgba(106, 95, 254, .12);
            font-size: 12px;
            color: #6a5ffe;
          }
        }
      }
      .order-content {
        color: #333;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        .item {
          padding: 12px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .img-item {
          margin-left: 20px;
          .img {
            width: 100px;
            height: 100px;
            overflow: hidden;
          }
        }
        .goods-item {
          width: 350px;
          margin-left: 16px;
          margin-right: 16px;
          flex-direction: column;
          .name {
            width: 100%;
            padding: 4px 0;
            line-height: 20px;
            font-weight: bold;
          }
          .params {
            width: 100%;
            padding: 4px 0;
            display: flex;
            align-items: center;
            .val {
              line-height: 22px;
              color: #999;
              word-break: break-all;
            }
            .copy {
              white-space: nowrap;
              margin-left: 6px;
              cursor: pointer;
              margin-right: 6px;
              padding: 0 6px;
              border-radius: 4px;
              background-color: rgba(106, 95, 254, .12);
              font-size: 12px;
              color: #6a5ffe;
            }
          }
        }
        .price, 
        .count,
        .money {
          justify-content: flex-start;
          padding: 0 15px;
          flex: 1;
          font-weight: bold;
        }
        .money {
          margin-right: 30px;
        }
        .detail {
          width: 130px;
          padding-left: 46px;
          border-left: 1px solid #eff4ff;
          border-right: 1px solid #eff4ff;
          flex-direction: column;
          align-items: flex-start;
          .value {
            line-height: 30px;
            span {
              color: #fe346e;
            }
          } 
        }
        .status {
          width: 176px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #46d59f;
          border-right: 1px solid #eff4ff;
          .status-4 {
            color: #3d3d42;
          }
          .status-5,
          .status-6,
          .status-7 {
            color: #fe346e;
          }
        }
        .operate {
          width: 176px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .btn {
            cursor: pointer;
            margin: 4px 0;
            color: #3d3d42;
            &.blue {
              color: #6a5ffe;
            }
            &.a {
              width: 76px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              border-radius: 6px;
              color: #fff;
              background-color: #6a5ffe;
            }
          }
        }
      }
    }
  }
}
</style>
